<template>
  <section>
    <product-list-pc v-if="showPc" />
    <product-list-mobile v-else />
  </section>
</template>

<script>
export default {
  components: {
    //productListPc: () => import("@/modules/product/views/productListPc.vue"),
     productListPc: () => import("@/modules/product/views/productListMobile.vue"),
    productListMobile: () =>
      import("@/modules/product/views/productListMobile.vue"),
  },
  computed: {
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
    showPc() {
      if (this.windowWidth >= 768) return true;
      return false;
    },
  },
};
</script>